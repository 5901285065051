@media print {
  body * {
    visibility: hidden;
  }
  .print-content, .print-content * {
    visibility: visible;
  }
  .print-content {
    width: 1024px;
    position: absolute;
    left: 0;
    top: 0;
  }
}
